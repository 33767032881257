export function normalizeEmail(email: string): string {
  return !email || !email.trim() ? "" : email.toLowerCase().trim();
}

export async function hashEmail(email: string): Promise<EmailHashes> {
  const url = "/api/email-hash";
  const search = new URLSearchParams({
    email: normalizeEmail(email),
  }).toString();
  const path = url + "?" + search.toString();
  const response = await fetch(path);
  const json = await response.json();
  return json;
}
