import {
  datadogRum,
  DefaultPrivacyLevel,
  RumInitConfiguration,
} from "@datadog/browser-rum";
import { RumPublicApi } from "@datadog/browser-rum-core";

class DatadogPlugin {
  /**
   * get the environment set in the environment variable
   * if not set it will try to look for the one in vercel
   * @private
   */
  private get env(): string {
    const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
    const nextEnv = process.env.NEXT_PUBLIC_DATADOG_ENV;

    if (nextEnv) {
      return nextEnv;
    }

    if (vercelEnv) {
      return vercelEnv;
    }

    return "unknown";
  }

  /**
   * Using the git commit hash as the version
   * TODO: discuss how to do proper versioning
   * @private
   */
  private get version(): string {
    const sha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

    return sha || "unknown";
  }

  /**
   * Check if the RUM is enabled
   */
  get isRumEnabled(): boolean {
    const environmentVariable = process.env.NEXT_PUBLIC_DATADOG_DISABLE_RUM;

    return environmentVariable
      ? !(environmentVariable.toLowerCase() === "true")
      : true;
  }

  /**
   * Get the configuration for the RUM
   * @private
   */
  private get config(): RumInitConfiguration {
    /**
     * Site has nothing to do with our sites but it is instead the RUM site.
     * By default it's `datadoghq.com` but we can change it to any regional site.
     * https://docs.datadoghq.com/getting_started/site/#pagetitle
     */
    const site = process.env.NEXT_PUBLIC_DATADOG_SITE ?? "datadoghq.com";

    const env = this.env;
    const version = this.version;

    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? "";
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "";
    const service = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? "";

    const sessionSampleRate = Number(
      process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE ?? 10,
    );

    const trackUserInteractions =
      process.env.NEXT_PUBLIC_DATADOG_TRACK_INTERACTIONS !== undefined
        ? process.env.NEXT_PUBLIC_DATADOG_TRACK_INTERACTIONS.toLowerCase() ===
          "true"
        : true;

    const defaultPrivacyLevel = DatadogPlugin.privacyLevelStringToEnum(
      process.env.NEXT_PUBLIC_DATADOG_DEFAULT_PRIVACY_LEVEL ?? "allow",
    );

    return {
      applicationId,
      clientToken,
      site,
      service,
      env,
      version,
      sessionSampleRate,
      sessionReplaySampleRate: sessionSampleRate,
      trackUserInteractions,
      defaultPrivacyLevel,
    };
  }

  constructor(private dd: RumPublicApi) {
    if (!this.isRumEnabled) {
      return;
    }

    try {
      dd.init(this.config);
      this.start();

      if (this.config.env !== "production") {
        // console.info("Datadog: started", this.config);
      }
    } catch (error) {
      console.error("Datadog: failed to start", error);
    }
  }

  /**
   * Start the RUM
   * @private
   */
  private start() {
    if (this.isRumEnabled) {
      this.dd.startSessionReplayRecording();
    }
  }

  /**
   * Add a custom action to the Rum instance
   *
   * @param name
   * @param context
   */
  public addAction(name: string, context?: object) {
    if (this.isRumEnabled) {
      this.dd.addAction(name, context);
    }
  }

  /**
   * Add a custom error to the Rum instance
   * @param error
   * @param context
   */
  public addError(error: unknown, context?: object) {
    if (this.isRumEnabled) {
      this.dd.addError(error, context);
    }
  }

  /**
   * Add a custom metric to the Rum instance
   * @param name
   */
  public addTiming(name: string) {
    if (this.isRumEnabled) {
      this.dd.addTiming(name);
    }
  }

  /**
   * Get privacy level from enum
   * @param privacyLevel
   * @private
   */
  private static privacyLevelStringToEnum(
    privacyLevel: string,
  ): DefaultPrivacyLevel {
    switch (privacyLevel) {
      case "mask":
        return DefaultPrivacyLevel.MASK;
      case "mask-user-input":
        return DefaultPrivacyLevel.MASK_USER_INPUT;
      default:
        return DefaultPrivacyLevel.ALLOW;
    }
  }
}

const datadog = new DatadogPlugin(datadogRum);
const getDatadogPlugin = () => datadog;

export default getDatadogPlugin;
