"use client";

import { Fragment, useEffect } from "react";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

interface ChicoryEvent {
  detail: {
    event_name: string;
    recipe_name: string;
  };
}

function chicoryTracker(e: Event) {
  const eventDetail = (e as any as ChicoryEvent)?.detail;
  const { event_name: eventName, recipe_name: recipeName } = eventDetail;
  if (eventName === "view_button") {
    gtag.event({
      action: TRACKING_EVENTS.chicory_audit_view,
      category: TRACKING_CATEGORIES.interaction,
      label: recipeName,
    });
    ClickServer.track(TRACKING_EVENTS.chicory_audit_view);
  } else if (eventName === "click_button") {
    gtag.event({
      action: TRACKING_EVENTS.chicory_audit_click,
      category: TRACKING_CATEGORIES.interaction,
      label: recipeName,
    });
    ClickServer.track(TRACKING_EVENTS.chicory_audit_click);
  } else if (eventName === "choose_grocer") {
    gtag.event({
      action: TRACKING_EVENTS.chicory_audit_grocer,
      category: TRACKING_CATEGORIES.interaction,
      label: recipeName,
    });
    ClickServer.track(TRACKING_EVENTS.chicory_audit_grocer);
  }
}

export default function ChicoryListener() {
  useEffect(() => {
    window.addEventListener("chicoryevent", chicoryTracker);
    return () => {
      window.removeEventListener("chicoryevent", chicoryTracker);
    };
  }, []);
  return <Fragment key="chicory-listener" />;
}
