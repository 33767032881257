import dynamic from "next/dynamic";

import Brands from "shared-lib/Brands";
import { brand } from "shared-lib/helpers/envFunctions";

const BigRecipeLogo = dynamic(() => import("components/brand/BigRecipe/logo"));
const CommandCookingLogo = dynamic(
  () => import("components/brand/CommandCooking/logo"),
);
const CookingProfessionallyLogo = dynamic(
  () => import("components/brand/CookingProfessionally/logo"),
);
const EasierEatsLogo = dynamic(
  () => import("components/brand/EasierEats/logo"),
);
const FussyKitchenLogo = dynamic(
  () => import("components/brand/FussyKitchen/logo"),
);
const HeartlandCookingLogo = dynamic(
  () => import("components/brand/HeartlandCooking/logo"),
);
const HolyRecipeLogo = dynamic(
  () => import("components/brand/HolyRecipe/logo"),
);
const LazyCookingLogo = dynamic(
  () => import("components/brand/LazyCooking/logo"),
);
const MamasCookingLogo = dynamic(
  () => import("components/brand/MamasCooking/logo"),
);
const RecipeReaderLogo = dynamic(
  () => import("components/brand/RecipeReader/logo"),
);
const RecipesDirectLogo = dynamic(
  () => import("components/brand/RecipesDirect/logo"),
);
const RecipeSaverLogo = dynamic(
  () => import("components/brand/RecipeSaver/logo"),
);

export default function Logo({ className = "", highContrast = false }) {
  switch (brand.id) {
    case Brands.bigrecipe: {
      return <BigRecipeLogo className={className} />;
    }
    case Brands.commandcooking: {
      return <CommandCookingLogo className={className} />;
    }
    case Brands.cookingprofessionally: {
      return <CookingProfessionallyLogo className={className} />;
    }
    case Brands.easiereats: {
      return (
        <EasierEatsLogo className={className} highContrast={highContrast} />
      );
    }
    case Brands.fussykitchen: {
      return <FussyKitchenLogo className={className} />;
    }
    case Brands.heartlandcooking: {
      return (
        <HeartlandCookingLogo
          className={className}
          highContrast={highContrast}
        />
      );
    }
    case Brands.holyrecipe: {
      return <HolyRecipeLogo className={className} />;
    }
    case Brands.lazycooking: {
      return (
        <LazyCookingLogo
          className={className}
          //  highContrast={highContrast}
        />
      );
    }
    case Brands.mamascooking: {
      return (
        <MamasCookingLogo
          className={className}
          //  highContrast={highContrast}
        />
      );
    }
    case Brands.recipesdirect: {
      return (
        <RecipesDirectLogo
          className={className}
          //  highContrast={highContrast}
        />
      );
    }
    case Brands.recipereader: {
      return (
        <RecipeReaderLogo
          className={className}
          //  highContrast={highContrast}
        />
      );
    }
    case Brands.recipesaver: {
      return (
        <RecipeSaverLogo
          className={className}
          //  highContrast={highContrast}
        />
      );
    }
  }
}
