"use client";

import { useEffect, useState } from "react";

import { usePathname } from "next/navigation";
import Script from "next/script";

import Brands from "shared-lib/Brands";
import { FB_PIXEL_ID, pageview } from "shared-lib/fpixel";
import { brand } from "shared-lib/helpers/envFunctions";
import { log } from "shared-lib/helpers/logging";

const FacebookPixel = () => {
  const [loaded, setLoaded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    log("FB track pixel id >>> ", FB_PIXEL_ID, loaded)
    if (!loaded) { return; }
    log("FB track pageView >>>> fb pixel")
    pageview();
  }, [pathname, loaded]);

  return (
    <>
      {(brand.id === Brands.commandcooking ||
        brand.id === Brands.cookingprofessionally ||
        brand.id === Brands.recipesaver ||
        brand.id === Brands.heartlandcooking ||
        brand.id === Brands.recipereader ||
        brand.id === Brands.bigrecipe) && (
        <>
          {/* Global Site Code Pixel - Facebook Pixel */}
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            onLoad={() => {
              log("FB track >>> onLoad, #fb-pixel element")
              setLoaded(true)
            }}
            data-pixel-id={FB_PIXEL_ID}
          >
            {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${FB_PIXEL_ID});
      `}
          </Script>
        </>
      )}
    </>
  );
};

export default FacebookPixel;
