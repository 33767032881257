"use client";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { useCustomExperiment } from "shared-lib/helpers/customStatSigHooks";
import { log } from "shared-lib/helpers/logging";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { setCookie } from "cookies-next";

import Brands from "shared-lib/Brands";
import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { setSubscribeDismissedCookie } from "shared-lib/helpers/cookieFunctions";
import { brand } from "shared-lib/helpers/envFunctions";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import Logo from "@/components/branded/logo";
import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import { LANDING_PAGE_PATHS } from "@/lib/constants";
import { getTitle } from "@/lib/helpers/brandFunctions";
import { getCategoryLink } from "@/lib/helpers/frontendFunctions";

import SearchBox from "./SearchBox";
import FullscreenModal from "../fullscreenModal";
import Subscribe from "../subscribe";
import { USE_EZOIC } from "shared-lib/constants";

function categoryClicked(categoryLink: CategoryLink) {
  logStatsig(TRACKING_EVENTS.navigation_bar_click, {
    click_location: categoryLink.name,
  });
  ClickServer.track(TRACKING_EVENTS.navigation_bar_click, {
    click_location: categoryLink.name,
  });
  gtag.event({
    action: TRACKING_EVENTS.navigation_bar_click,
    category: TRACKING_CATEGORIES.navigation,
    label: categoryLink.name,
  });
}

interface CategoryLink {
  name: string;
  route: string;
  current: boolean;
}

export default function Header_Client({
  categories = [],
  category,
  preview = false,
}: {
  categories: Category_Recipe[];
  category?: Category_Recipe;
  preview?: boolean;
}) {
  const [globalState, dispatch] = useGlobalState();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const [subscribeOpen, setSubscribeOpen] = useState(false);
  const [paidOrIntro, setPaidOrIntro] = useState(false);

  const isLanding = LANDING_PAGE_PATHS.includes(pathName);
  const showNavigation = categories.length > 0 && !isLanding;

  // Subscribe modal delay
  const timerRef = useRef<any>(null);

  const validateEzoicsTrackingImplementationStage2 = useCustomExperiment(
    "validate_ezoics_tracking_implementations_stage_2",
  );

  useEffect(() => {
    log("Ezoic: set Ezoic_Metatag_Validation cookie: ", "cookieControl");
    setCookie("Ezoic_Metatag_Validation", "cookieControl");
  }, [USE_EZOIC]);

  useEffect(() => {
    log(
      "Ezoic: Validate Ezoics Tracking Implementations Stage 2: ",
      validateEzoicsTrackingImplementationStage2,
    );
  }, [validateEzoicsTrackingImplementationStage2]);

  useEffect(() => {
    if (globalState.currentRecipe?.entry && pathName !== "/recipe/[slug]") {
      console.log("setting current recipe to undefined");
      dispatch({
        type: GlobalActionTypes.SET_CURRENT_RECIPE,
        payload: undefined,
      });
    }
  }, [dispatch, globalState.currentRecipe, pathName]);

  const categoryLinks = categories
    .filter((c) => {
      return c.showInNavigationBar;
    })
    .sort((c1, c2) => {
      return (c1.order ?? 0) - (c2.order ?? 0);
    })
    .map((c) => {
      const categoryLink = getCategoryLink(c);
      return {
        name: c.name,
        route: categoryLink,
        current:
          categoryLink === pathName ||
          category?.name === c.name ||
          (globalState.currentRecipe?.entry as Recipe)?.category?.name ===
            c.name,
      } as CategoryLink;
    });

  const popoverRef = useRef<HTMLDivElement>(null);

  // prevent scrolling on body when popover is up
  useEffect(() => {
    if (popoverRef.current) {
      const observer = new MutationObserver(() => {
        const popoverIsOpen =
          popoverRef.current?.getAttribute("data-headlessui-state") === "open";
        if (popoverIsOpen) {
          document.body.classList.add("overflow-hidden");
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      });
      observer.observe(popoverRef.current, {
        attributes: true,
      });
      return () => observer.disconnect();
    }
  }, [popoverRef]);

  // Detect if paid or intro traffic
  useEffect(() => {
    const [source, medium] = [
      (searchParams?.get("utm_source") ?? "").toLowerCase(),
      (searchParams?.get("utm_medium") ?? "").toLowerCase(),
    ];

    const isPaid = medium.includes("display");
    const isIntro = source.includes("intro");
    setPaidOrIntro(isPaid || isIntro);

    const now = Date.now();
    setCookie("openModal", now);

    if (isPaid) {
      setCookie("isPaidTraffic", "true");
    }
  }, [preview, searchParams, pathName]);

  const closeModalMetrics = useCallback(
    (event: KeyboardEvent) => {
      if (event?.key === "Escape" && globalState.missingSubscribe) {
        logStatsig(TRACKING_EVENTS.subscribe_modal_dismissed, {
          exit_action: "keyboard",
        });
        gtag.event({
          action: TRACKING_EVENTS.subscribe_modal_dismissed,
          category: TRACKING_CATEGORIES.interaction,
          label: "keyboard",
        });
        ClickServer.track(TRACKING_EVENTS.subscribe_modal_dismissed, {
          exit_action: "keyboard",
        });
        setSubscribeOpen(false);
        setSubscribeDismissedCookie();
      }
    },
    [globalState.missingSubscribe],
  );

  useEffect(() => {
    if (subscribeOpen) {
      window.addEventListener("keydown", closeModalMetrics);
    }
    return () => {
      window.removeEventListener("keydown", closeModalMetrics);
    };
  }, [closeModalMetrics, subscribeOpen]);

  let logoColorClasses = "";
  if (
    [
      Brands.commandcooking,
      Brands.cookingprofessionally,
      Brands.easiereats,
      Brands.heartlandcooking,
    ].includes(brand.id)
  ) {
    logoColorClasses = "hover:fill-theme-primary-dark";
  }

  return (
    <>
      {USE_EZOIC && (
        <meta name="Ezoic_Metatag_Validation" content="metaControl" />
      )}
      {validateEzoicsTrackingImplementationStage2 && (
        <meta
          name="Ezoic_Metatag_Validation_Stage_2"
          content={validateEzoicsTrackingImplementationStage2}
        />
      )}
      <nav
        className={clsx(
          "top-0 z-20 flex h-16 items-center border-b-2 border-theme-gray-100 bg-white sm:h-20 lg:sticky print:hidden",
          (showNavigation && "px-8") || "px-4",
          isLanding && "justify-center",
        )}
      >
        {isLanding ? (
          <Logo
            className={clsx(
              "flex h-20 transition-colors duration-200 ease-in",
              logoColorClasses,
            )}
          />
        ) : (
          <>
            <Popover ref={popoverRef} className="mr-4 lg:hidden">
              {({ open, close }) => (
                <>
                  {showNavigation && (
                    <PopoverButton className="flex">
                      {open ? (
                        <XMarkIcon className="h-10 rounded-md stroke-black duration-300 hover:bg-theme-primary-dark hover:stroke-white focus:bg-gray-700 focus:text-white focus:outline-none" />
                      ) : (
                        <Bars3Icon className="h-10 rounded-md stroke-black duration-300 hover:bg-theme-primary-dark hover:stroke-white focus:bg-gray-700 focus:text-white focus:outline-none" />
                      )}
                    </PopoverButton>
                  )}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-500"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-500"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <PopoverPanel className="absolute left-0 z-40 mt-[10px] flex h-screen w-full flex-col space-y-4 bg-white/90 px-8 py-4 backdrop-blur-xl sm:mt-[18px]">
                      <SearchBox
                        className="flex !w-full sm:hidden"
                        onSearch={() => {
                          close();
                        }}
                      />
                      <div className="flex flex-col space-y-4 text-xl">
                        {categoryLinks.map((item) => (
                          <PopoverButton
                            as={Link}
                            href={item.route}
                            key={item.name}
                            onClick={() => categoryClicked(item)}
                            className={clsx(
                              "transition-colors duration-200 ease-in-out hover:text-theme-primary-dark",
                              item.current
                                ? "font-bold text-theme-primary-dark"
                                : "text-theme-gray-300",
                            )}
                          >
                            {item.name}
                          </PopoverButton>
                        ))}
                        <PopoverButton
                          as={Link}
                          href={"/article"}
                          className={clsx(
                            "transition-colors duration-200 ease-in-out hover:text-theme-primary-dark",
                            pathName == "/article"
                              ? "font-bold text-theme-primary-dark"
                              : "text-theme-gray-300",
                          )}
                        >
                          Blog
                        </PopoverButton>
                      </div>
                    </PopoverPanel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link
              aria-label="Home"
              href="/"
              className="duration-300 ease-linear"
              onClick={() => {
                logStatsig(TRACKING_EVENTS.navigation_bar_click, {
                  click_location: "logo",
                });
                ClickServer.track(TRACKING_EVENTS.navigation_bar_click, {
                  click_location: "logo",
                });
                gtag.event({
                  action: TRACKING_EVENTS.navigation_bar_click,
                  category: TRACKING_CATEGORIES.navigation,
                  label: "logo",
                });
              }}
            >
              <Logo
                className={clsx(
                  "flex h-10 transition-colors duration-200 ease-in",
                  logoColorClasses,
                )}
              />
            </Link>

            {showNavigation && (
              <div className="flex p-4">
                <span
                  className={clsx(
                    "hidden h-12 border-l-2 border-theme-gray-100 lg:flex",
                    category && "md:flex",
                  )}
                />
              </div>
            )}

            <div className="relative flex h-full flex-grow items-center">
              {category && (
                <Link
                  className="hidden h-full items-center border-b-2 border-transparent text-sm font-bold text-black transition-colors duration-200 ease-in-out hover:border-theme-primary-dark hover:text-theme-primary-dark md:inline-flex lg:hidden"
                  href={getCategoryLink(category)}
                >
                  {category.name}
                </Link>
              )}

              {pathName !== "/1p" && (
                <>
                  <div className="hidden h-full space-x-4 lg:block">
                    {categoryLinks.map((item) => (
                      <Link
                        href={item.route}
                        key={item.name}
                        onClick={() => categoryClicked(item)}
                        className={clsx(
                          "inline-flex h-full items-center border-b-2 border-transparent text-sm transition-colors duration-200 ease-in-out hover:border-theme-primary hover:text-theme-primary",
                          item.current
                            ? "border-theme-primary font-bold text-theme-primary"
                            : "text-theme-gray-300",
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}

                    <Link
                      href={"/article"}
                      className={clsx(
                        "inline-flex h-full items-center border-b-2 border-transparent text-sm transition-colors duration-200 ease-in-out hover:border-theme-primary hover:text-theme-primary",
                        pathName == "/article"
                          ? "border-theme-primary font-bold text-theme-primary"
                          : "text-theme-gray-300",
                      )}
                    >
                      Blog
                    </Link>
                  </div>

                  <div className="grow" />

                  <Popover className="hidden sm:flex xl:hidden">
                    {({ open, close }) => (
                      <>
                        <PopoverButton>
                          {!open && (
                            <MagnifyingGlassIcon className="h-8 w-8 cursor-pointer rounded-md bg-theme-gray-100 p-2 duration-300 ease-linear hover:bg-theme-primary-dark hover:stroke-white" />
                          )}
                        </PopoverButton>

                        <PopoverPanel className="absolute inset-0 flex items-center">
                          <SearchBox
                            className="flex h-full !w-full"
                            focus
                            onSearch={() => {
                              close();
                            }}
                          />
                        </PopoverPanel>
                      </>
                    )}
                  </Popover>

                  <SearchBox className="mr-8 hidden xl:block" />

                  <button
                    onClick={() => {
                      clearTimeout(timerRef.current);
                      setSubscribeOpen(!subscribeOpen);
                      logStatsig(TRACKING_EVENTS.subscribe_open);
                      ClickServer.track(TRACKING_EVENTS.subscribe_open);
                      gtag.event({
                        action: TRACKING_EVENTS.subscribe_open,
                        category: TRACKING_CATEGORIES.interaction,
                        label: getTitle(""),
                      });
                    }}
                    className="h-full border-b-2 border-transparent font-bold uppercase text-theme-primary transition-colors duration-200 ease-in-out hover:border-b-theme-secondary hover:text-theme-secondary"
                    data-testid="subscribe-button"
                  >
                    subscribe
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </nav>

      <FullscreenModal
        isOpen={subscribeOpen}
        showX={!paidOrIntro}
        onClose={() => {
          setSubscribeDismissedCookie();
          setSubscribeOpen(false);
        }}
        washOnClick={() => {
          logStatsig(TRACKING_EVENTS.subscribe_modal_dismissed, {
            exit_action: "click_wash",
          });
          ClickServer.track(TRACKING_EVENTS.subscribe_modal_dismissed, {
            exit_action: "click_wash",
          });
          gtag.event({
            action: TRACKING_EVENTS.subscribe_modal_dismissed,
            category: TRACKING_CATEGORIES.interaction,
            label: "click_wash",
          });
        }}
        closeOnClick={() => {
          logStatsig(TRACKING_EVENTS.subscribe_modal_dismissed, {
            exit_action: "click_x",
          });
          ClickServer.track(TRACKING_EVENTS.subscribe_modal_dismissed, {
            exit_action: "click_x",
          });
          gtag.event({
            action: TRACKING_EVENTS.subscribe_modal_dismissed,
            category: TRACKING_CATEGORIES.interaction,
            label: "click_x",
          });
        }}
      >
        <Subscribe />
      </FullscreenModal>
    </>
  );
}
