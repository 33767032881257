"use client";

import { useEffect, Fragment } from "react";

import { usePathname } from "next/navigation";

import getDatadogPlugin from "shared-lib/datadog";
import {
  setSubscribedStateCookies,
  setUtmParamsCookie,
} from "shared-lib/helpers/cookieFunctions";
import { exposeConsoleLogMethod } from "shared-lib/helpers/logging";
import {
  TRACKING_EVENTS,
  logStatsig,
} from "shared-lib/helpers/statsigFunctions";

import {
  wkClick,
  wkClose,
  wkAccepted,
  wkAdType,
} from "@/lib/helpers/wkFunctions";

export default function TrackerCode() {
  const pathName = usePathname();
  useEffect(() => {
    getDatadogPlugin();
    setUtmParamsCookie();
    setSubscribedStateCookies();

    exposeConsoleLogMethod();

    document.addEventListener("wk_accepted", wkAccepted);
    document.addEventListener("wk_clicked", wkClick);
    document.addEventListener("wk_closed", wkClose);
    document.addEventListener("wknd_adtype", wkAdType);
    return () => {
      document.removeEventListener("wk_accepted", wkAccepted);
      document.removeEventListener("wk_clicked", wkClick);
      document.removeEventListener("wk_closed", wkClose);
      document.removeEventListener("wknd_adtype", wkAdType);
    };
  }, []);

  useEffect(() => {
    logStatsig(TRACKING_EVENTS.pageview);
  }, [pathName]);

  return <Fragment key="tracking-code" />;
}
