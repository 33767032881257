"use client";
import { Fragment } from "react";

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function FullscreenModal({
  isOpen,
  showX = false,
  onClose = () => {
    return;
  },
  washOnClick,
  closeOnClick,
  children,
}: {
  isOpen: boolean;
  showX?: boolean;
  onClose?: any;
  washOnClick: () => void;
  closeOnClick: () => void;
  children: any;
}) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={() => {
          onClose();
          washOnClick();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          />
        </TransitionChild>

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <DialogPanel>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-[10px] bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:align-middle md:max-w-2xl">
                {showX && (
                  <button
                    id="recipe-close-x"
                    className="absolute right-2 top-2 z-40 h-5 w-5"
                    onClick={() => {
                      onClose();
                      closeOnClick();
                    }}
                  >
                    <XMarkIcon className="h-5 w-5 stroke-black/50 transition-colors duration-200 hover:stroke-red-500" />
                  </button>
                )}
                {children}
              </div>
            </TransitionChild>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
}
