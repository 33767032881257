"use client";

import { useEffect } from "react";

import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import { PAGE_NAMES } from "@/lib/constants";

export default function HomeSlugPageClient() {
  const [, dispatch] = useGlobalState();

  useEffect(() => {
    dispatch({
      type: GlobalActionTypes.SET_VISITED_PAGE,
      payload: PAGE_NAMES.HOME,
    });
  }, [dispatch]);

  return <></>;
}
