import clsx from "clsx";

export default function Spinner({ className = "", small = false }) {
  return (
    <div
      className={clsx(
        className,
        small ? "h-32 w-32" : "h-64 w-64",
        "mx-auto animate-spin rounded-full border-8 border-t-8 border-theme-gray-100 border-t-theme-primary ease-linear",
      )}
    />
  );
}
