import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/app/client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/app/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/ChicoryListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/layout/Header_Client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/recipe/TrackerCode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-lato\",\"weight\":[\"400\",\"700\"]}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Merriweather\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-merriweather\",\"weight\":[\"400\",\"700\"]}],\"variableName\":\"merriweather\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/shared-components/components/tracking/ClickServer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/shared-components/components/tracking/FacebookPixel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/shared-components/components/tracking/GATracker.tsx");
