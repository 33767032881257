export function convertSlugToTitle(slug: string) {
  return decodeURI(slug);
}

export function getTagLink(title: string) {
  return "/tag/" + encodeURIComponent(title);
}

export function getContentPath(path: string) {
  const contentPath = path.split("/")[1];
  switch (contentPath) {
    case "recipe":
    case "joke":
    case "word":
    case "category":
    case "article":
    case "tag":
      return contentPath;
    default:
      return "other";
  }
}
