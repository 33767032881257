"use client";
import { useCallback, useEffect, useRef, useState } from "react";

import Link from "next/link";

import clsx from "clsx";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocalStorage } from "usehooks-ts";

import {
  handleSessionId,
  isAllowedEmail,
  handleUserId,
} from "shared-lib/helpers/utilityFunctions";
import Brands from "shared-lib/Brands";
import { ClickServer } from "shared-lib/clickserver";
import * as fbq from "shared-lib/fpixel";
import * as gtag from "shared-lib/gtag";
import {
  getUtmParams,
  setHasSubscribedCookie,
} from "shared-lib/helpers/cookieFunctions";
import { hashEmail } from "shared-lib/helpers/emailFunctions";
import { brand } from "shared-lib/helpers/envFunctions";
import { log } from "shared-lib/helpers/logging";
import {
  TRACKING_CATEGORIES,
  TRACKING_EVENTS,
} from "shared-lib/helpers/statsigFunctions";
import Logo from "@/components/branded/logo";
import Spinner from "@/components/spinner";
import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import { getRecipeImageURL } from "@/lib/helpers/backendFunctions";

const desktopBackgroundImage =
  "https://images.ctfassets.net/7t85a6f8wt2m/3Ud69hLS2KTWXY5Rrjrhfc/b25f1f15e1bb64798c2a1c6ac7f563bf/EE_Image_4.jpeg?w=1344&h=768&fit=fill&f=center&q=25";
const mobileBackgroundImage =
  "https://images.ctfassets.net/7t85a6f8wt2m/3Ud69hLS2KTWXY5Rrjrhfc/b25f1f15e1bb64798c2a1c6ac7f563bf/EE_Image_4.jpeg?w=700&h=1050&fit=fill&f=center&q=25";

export default function Subscribe({ className = "" }: { className?: string }) {
  const [localParams] = useLocalStorage("params", { value: "{}" });
  const [globalState, dispatch] = useGlobalState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [errors, setErrors] = useState({ email: false });
  const [email, setEmail] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [failed, setFailed] = useState(false);

  const emailInput = useRef(null);
  useEffect(() => {
    if (emailInput.current) {
      (emailInput.current as HTMLElement).focus();
    }
  }, []);

  const currentRecipe =
    (globalState.currentRecipe.entry as Recipe) || undefined;
  const mobileBackgroundImageInExperiment = currentRecipe
    ? getRecipeImageURL(currentRecipe, 600, 450, "50")
    : "";
  const desktopBackgroundImageInExperiment = currentRecipe
    ? getRecipeImageURL(currentRecipe, 600, 450)
    : "";

  const submitForm = () => {
    const { isValid, message } = isAllowedEmail(email);
    setErrors({ email: isValid });
    setShowErrors(!isValid);
    setErrorMessage(message);
    setSucceeded(false);
    setFailed(false);
    if (isValid) {
      setProcessing(true);
      handleReCaptchaVerify();
    }
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (typeof executeRecaptcha === "undefined") {
      log("Execute recaptcha not yet available");
      console.info("RECAPTCHA FAIL");
      setFailed(true);
      setProcessing(false);
      return;
    }

    const token = await executeRecaptcha!("submit");

    const submitSubscriber = async (token: string) => {
      setProcessing(true);
      gtag.event({
        action: TRACKING_EVENTS.subscribe_form_valid,
        category: TRACKING_CATEGORIES.interaction,
        label: "subscribe_form",
      });
      ClickServer.track(TRACKING_EVENTS.subscribe_form_valid);
      dispatch({
        type: GlobalActionTypes.SET_MISSING_SUBSCRIBE,
        payload: false,
      });

      try {
        const utmParams = getUtmParams();
        const localStorageValues = JSON.parse(localParams.value);

        const ipResponse = await fetch("/api/get-client-ip-address");
        const data = await ipResponse.json();
        const clientIP =
          data?.realIP ?? data?.ipAddress ?? data?.forwardedFor ?? "Unknown";

        const res = await fetch("/api/subscribe", {
          method: "POST",
          body: JSON.stringify({
            email,
            token,
            utmParams,
            localStorage: localStorageValues,
            signup_ip: clientIP,
            user_id: handleUserId(),
            session_id: handleSessionId(),
            user_agent: navigator.userAgent,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        log({ res });

        const signUp = await res.json().catch((e: any) => {
          log({ message: e });
          gtag.event({
            action: TRACKING_EVENTS.subscribe_failed,
            category: TRACKING_CATEGORIES.interaction,
            label: "MALFORMED_RESPONSE",
          });
          ClickServer.track(TRACKING_EVENTS.subscribe_failed, {
            statusCode: "MALFORMED_RESPONSE",
          });
          setFailed(true);
          return;
        });

        if (!signUp.isValid) {
          gtag.event({
            action: TRACKING_EVENTS.subscribe_failed,
            category: TRACKING_CATEGORIES.interaction,
            label: signUp.statusCode,
          });
          ClickServer.track(TRACKING_EVENTS.subscribe_failed, {
            statusCode: signUp.statusCode,
          });
        }

        // Handle Server Errors
        if (res.status === 403 || res.status === 404 || res.status === 422) {
          setFailed(true);
          return;
        }

        // Handle Server-side Email Validation
        // Allow user to correct the email address
        if (
          res.status === 400 &&
          (signUp.statusCode === "INVALID_EMAIL" ||
            signUp.statusCode === "EMAIL_FAILS_VALIDATION")
        ) {
          const { message } = signUp;
          log({ message });
          setErrorMessage("The email address is invalid.");
          setShowErrors(true);
          setSucceeded(false);
          setFailed(false);
          return;
        }

        if (res.status === 200) {
          dispatch({
            type: GlobalActionTypes.SET_MISSING_SUBSCRIBE,
            payload: false,
          });

          gtag.event({
            action: TRACKING_EVENTS.subscribed,
            category: TRACKING_CATEGORIES.interaction,
            label: "modal",
          });
          const {
            md5,
            sha1,
            sha256,
            email: cleanedEmail,
          } = await hashEmail(email);
          if (cleanedEmail) {
            ClickServer.setIdentity({ md5, sha1, sha256 });
          }
          ClickServer.track(TRACKING_EVENTS.subscribed, {
            signup_location: "modal",
            user_agent: navigator.userAgent,
          });
          if (
            brand.id === Brands.commandcooking ||
            brand.id === Brands.cookingprofessionally ||
            brand.id === Brands.recipesaver ||
            brand.id === Brands.heartlandcooking ||
            brand.id === Brands.recipereader ||
            brand.id === Brands.bigrecipe
          ) {
            fbq.event("subscribe");
          }
          setHasSubscribedCookie();
          setEmail("");
          setSucceeded(true);
        }
      } catch (error: any) {
        console.error(error.toString());
      } finally {
        setProcessing(false);
      }
    };
    await submitSubscriber(token);
    // TODO: determine if this can / should be exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, dispatch, executeRecaptcha]);

  return (
    <>
      {!succeeded && !failed && (
        <div className="absolute inset-0 z-0">
          <div
            className="hidden h-full w-full bg-gray-400 bg-cover bg-center bg-no-repeat md:block"
            style={{
              backgroundImage: desktopBackgroundImageInExperiment
                ? `url(${desktopBackgroundImageInExperiment})`
                : `url(${desktopBackgroundImage})`,
            }}
          />
          <div
            className="h-full w-full bg-gray-400 bg-cover bg-center bg-no-repeat md:hidden"
            style={{
              backgroundImage: mobileBackgroundImageInExperiment
                ? `url(${mobileBackgroundImageInExperiment})`
                : `url(${mobileBackgroundImage})`,
            }}
          />
        </div>
      )}

      <div
        id="subscribe"
        className={clsx(
          className,
          (succeeded || failed) && "hidden",
          "relative text-theme-primary-dark",
        )}
      >
        <div className="flex flex-col gap-2 text-center">
          <div className="basis-5/12 bg-white/80 px-3 py-4 md:px-[12px]">
            <div className="mx-auto md:w-3/5">
              <Logo className="mx-auto mb-4 h-12" />
              <h2 className="font-lato mb-4 text-3xl font-bold leading-7 text-gray-900">
                Stay Connected
              </h2>
              <p className="text-2xl text-gray-700">
                {currentRecipe ? (
                  <>
                    Sign up to see {currentRecipe?.title} and thousands of other{" "}
                    <b>always-free</b> recipes.
                  </>
                ) : (
                  <>
                    Sign up to see thousands of <b>always-free</b> recipes.
                  </>
                )}
              </p>
              {processing && <Spinner className="mt-8 h-16 w-16" />}
            </div>
          </div>

          <div className="basis-7/12 px-9 py-4 md:px-[20px]">
            <form
              autoComplete="on"
              className="mt-6"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <div className="flex flex-col md:pr-5">
                <div className="relative mx-auto mt-2 w-full pb-5 md:w-2/3">
                  <input
                    ref={emailInput}
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    placeholder="Your Email Address?"
                    value={email}
                    onChange={({ target: { value } }) => {
                      setEmail(value);
                    }}
                    className={clsx(
                      "mx-auto block w-full rounded-md border-black/25 p-3 text-2xl shadow-sm focus:border-indigo-500 focus:ring-indigo-500",
                      showErrors &&
                        errors.email &&
                        "border-[#FBCFBD] bg-[#FBCFBD]",
                    )}
                  />
                  <p
                    className={clsx(
                      !showErrors && "hidden",
                      "absolute bottom-0 w-full rounded-md bg-[#FBCFBD] text-center text-sm text-theme-error",
                      showErrors && errors.email && "border border-[#F0500F]",
                    )}
                  >
                    {errorMessage || "Email must be a valid address"}
                  </p>
                </div>
                <div className="mt-4 flex flex-col">
                  <button
                    type="button"
                    data-testid="subscribe-submit-button"
                    disabled={processing}
                    className="font-lato mx-auto rounded-md bg-ee-red p-5 text-center text-3xl font-bold text-white transition-colors duration-300 enabled:hover:text-blue-300 disabled:cursor-not-allowed disabled:bg-theme-gray-200 md:w-[56%]"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Sign Up
                  </button>

                  <p className="break-word hidden px-1 py-2 text-center text-xs text-white lg:hidden">
                    By submitting this form, you are agreeing to our{" "}
                    <Link
                      href="/terms"
                      className="whitespace-nowrap underline hover:text-blue-500"
                    >
                      Terms of Use
                    </Link>
                    . This site is protected by reCAPTCHA and the Google{" "}
                    <a
                      className="whitespace-nowrap underline hover:text-blue-500"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      className="whitespace-nowrap underline hover:text-blue-500"
                      href="https://policies.google.com/terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </p>
                </div>
              </div>
            </form>
          </div>

          <div className="bg-black/40 px-8 py-4 text-white">
            <p className="break-word m-auto text-center text-xs md:w-1/2">
              By submitting this form, you are agreeing to our{" "}
              <Link
                href="/terms"
                className="whitespace-nowrap underline hover:text-blue-500"
              >
                Terms of Use
              </Link>
              . This site is protected by reCAPTCHA and the Google{" "}
              <a
                className="whitespace-nowrap underline hover:text-blue-500"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="whitespace-nowrap underline hover:text-blue-500"
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>{" "}
              apply.
            </p>
          </div>
        </div>
      </div>

      {/* Failed/Success screen */}
      <>
        {succeeded && (
          <div
            data-testid="subscribe-success-modal"
            className="align-center flex flex-col items-center space-y-8 p-8"
          >
            <Logo className="h-16" />
            <p className="text-5xl">😋</p>
            <hr className="border-t-1 w-full max-w-full border-black bg-black md:max-w-[50%]" />
            <h2 className="text-4xl font-normal">Thanks for Subscribing!</h2>
          </div>
        )}

        {failed && (
          <div className="align-center flex flex-col items-center space-y-8 p-8">
            <Logo className="h-16" />
            <p className="text-5xl">😔</p>
            <hr className="border-t-1 w-full max-w-full border-black bg-black md:max-w-[50%]" />
            <h2 className="text-4xl font-normal">
              Something went wrong. Please try again another time.
            </h2>
          </div>
        )}
      </>
      {(brand.id === Brands.commandcooking ||
        brand.id === Brands.cookingprofessionally ||
        brand.id === Brands.recipesaver ||
        brand.id === Brands.heartlandcooking) && (
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt=""
            src={`https://www.facebook.com/tr?id=${fbq.FB_PIXEL_ID}&ev=Subscribe&noscript=1`}
          />
        </noscript>
      )}
    </>
  );
}
