"use client";
import { useCallback, useEffect, useRef } from "react";

import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import { getTitle } from "@/lib/helpers/brandFunctions";

function searchRoute(q: string) {
  const params = new URLSearchParams(`?q=${q}`);

  return "/search?" + params.toString();
}

export default function SearchBox({
  className = "",
  focus = false,
  onSearch = () => {
    return;
  },
  recipeTitle = "",
}) {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);

  const slashFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "/") {
      inputRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (focus) {
      inputRef?.current?.focus();
    }

    document.addEventListener("keyup", slashFunction, false);

    return () => {
      document.removeEventListener("keyup", slashFunction, false);
    };
  }, [focus, slashFunction]);

  function onClickButton(
    event: any, // React.FormEvent<HTMLFormElement>,
    router: AppRouterInstance,
  ) {
    event.preventDefault();
    const searchQueryInput = event.target.searchQuery;
    router.push(searchRoute(searchQueryInput.value));
    onSearch();
    searchQueryInput.blur();
  }

  return (
    <form
      className={clsx(
        className,
        "relative flex h-10 w-48 rounded-md border-[1px] border-gray-100 bg-white",
      )}
      onSubmit={(e) => onClickButton(e, router)}
    >
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon
          aria-hidden="true"
          className="h-5 w-5 stroke-theme-gray-300"
        />
      </div>

      <input
        ref={inputRef}
        id="searchQuery"
        name="searchQuery"
        className="h-full w-full rounded-md pl-10 text-theme-primary-dark"
        placeholder="Search"
        onFocus={() => {
          logStatsig(TRACKING_EVENTS.search_open);
          ClickServer.track(TRACKING_EVENTS.search_open);
          gtag.event({
            action: TRACKING_EVENTS.search_open,
            category: TRACKING_CATEGORIES.interaction,
            label: getTitle(recipeTitle),
          });
        }}
      />
    </form>
  );
}
